.categorySkeletonPage
  height: 100%
  overflow: hidden

.explore
  height: calc( 100% - 180px )
  .glossaryCategory:first-child
    .heading
      padding-top: 0
      margin-top: 0
  .wordLists
    height: 100%
    overflow-x: hidden
    overflow-y: overlay
    .heading
      padding: 12px 0
      text-transform: capitalize
      margin-top: 24px
      color: var(--brand)
      font-size: 16px
  .glossaryList
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr))
    gap: 12px
    padding: 12px
    padding-top: 0
  .glossaryCard
    all: unset
    cursor: pointer
    height: 100%
    width: 100%
    background-color: var(--card)
    border-radius: 10px 10px
    .wordsNumSkeleton
      border-radius: 100px 100px !important
      padding: 0 !important
      background-image: linear-gradient( var(--card) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--primary) 100%, transparent 0 )
        
    .wordsNumSkeleton:before
      background-image: linear-gradient( #353535 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--border) 100%, transparent 0 )
      border-radius: 100px 100px !important 
    .image
      width: 100%
      height: 150px
      position: relative
      border-radius: 10px 10px 0 0
      img
        object-fit: cover
        border-radius: 10px 10px 0 0
      svg
          z-index: 3
          position: absolute
          display: block
          border-radius: 10px 10px 0 0
          padding: 24px
          width: 100%
          height: calc( 100% + 1px )
          top: 0
          transform: scale(1)
          fill: var(--text2)
          background-color: var(--card)
          path
              fill: var(--text2)
      .wordsNum
        position: absolute
        right: 6px
        bottom: 6px
        background-color: var(--background)
        padding: 10px 16px
        border-radius: 100px 100px
        border: 1px solid var(--border)
        color: var(--text)
        font-size: 14px
        span
          color: var(--secondary)
    .content
      padding: 12px
      .category
        color: var(--text2)
        font-size: 14px
        margin-top: 6px
      .name
        font-size: 24px
        
.arrowRight
  all: unset
  position: absolute
  right: 18px
  display: flex
  align-items: center
  svg
    position: static !important
    margin-left: 12px
  .text
    color: var(--text2)