.empty
    display: flex
    justify-content: center
    align-items: center
    flex-flow: column
    transition: 0.3s
    width: 100%
    .img
        min-width: 96px
        min-height: 96px
        width: 96px
        height: 96px
        transform: translateX(9px)
        position: relative
    p
        font-size: var(--textSize)
        margin: 24px 0
        text-align: center
        line-height: 1.75
        mark
            all: unset
            color: var(--brand)
            font-style: italic
    .link
        all: unset
        display: flex
        align-items: center
        justify-content: center
        background-color: var(--primary)
        padding: 14px
        border-radius: 10px 10px
        cursor: pointer
        font-size: 16px
        svg
            margin-right: 12px
    .button
        width: calc( 100% - 48px )
    .subText
        color: var(--text2)
        margin-top: 12px
        font-size: 12px